.swiper-slider{
    overflow: hidden !important;
    &__slide{
        max-height: 100vh;
        &__image{
            width: 100%;
        }
        &__content-box{
            background: rgba(0, 0, 0, 0.371);
        }
    }
}
.swiper-slider-product{
    .swiper-pagination-bullet{
        width: 9px;
        height: 9px;
        background: gray !important;
        margin-right: 5px;
        border-radius: 50%;
        &.swiper-pagination-bullet-active{
            width: 18px;
            background: #6868ad !important;
            border-radius: 13px;
        }
    }
}
.swiper-pagination{
    display: flex;
    position: absolute;
    bottom: 20px;
    z-index: 9999;
    width: 100%;
    justify-content: center;
    .swiper-pagination-bullet{
        width: 9px;
        height: 9px;
        background: white;
        margin-right: 5px;
        border-radius: 50%;
        &.swiper-pagination-bullet-active{
            width: 18px;
            background: #6868ad;
            border-radius: 13px;
        }
    }
}

