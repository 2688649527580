.panel-widget-list{
    max-height: 600px;
    overflow: auto;
    .widget-title{
        position: sticky;
        background: white;
        top: 0;
    }
}
.dashboard{
    grid-template-columns: repeat(2, 1fr);
}
.excel-step{
    img{
        object-fit: contain;
    }
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    margin: 0 auto;
}

.excel-file-upload{
    max-width: fit-content;
    margin: 0 auto;
}
.excel-file-name{
    font-size: 10px;
}
.modal-content{
    max-height: 80vh;
    overflow: auto;
}
.brand-dist-wrapper{
    max-width: 800px !important;
}
.find-company{
    img{
        max-height: 300px;
        object-fit: contain;
    }

}

.find-location-container{
    height: calc(100vh - 102px);
}
.dashboard-company{
    .user-filter-box{
        display: none;
    }
    .edit-button,.edit-price-button{
        display: none;
    }
    .add-company{
        display: none;
    }
}
.dashboard-users{
    .user-filter-box{
        display: none;
    }
    .add-user,.edit-user-th,.edit-price-th{
        display: none;
    }
    .edit-button,.edit-price-button{
        display: none;
    }
    .table-responsive{
        max-height: 500px;
        min-height: unset;
    }
}
.excel-result-info{
    button{
        color: white;
    }
}
.excel-result-step{
    img{
        object-fit: contain;
    }
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    margin: 0 auto;
}
.table-responsive{
    min-height: 500px;
}
.panel-home-page {
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    gap: 10px;
    &__links {
        border: 1px solid black;
        border-radius: 2px;
        padding: 20px;
        color: black;
    }
}
.filter-user-search{
    label {
        padding: 0 1rem !important;
        top: 1rem !important;
    }
    input{
        height: 38px !important;
        padding: 0 10px !important;
        padding-left: 40px !important;
    }
}

.user-prices {
    max-height: 200px;
    overflow-y: auto !important;
}