
.swiper-slide{
  &__slide{
      &__content-box{
          background: rgba(0, 0, 0, 0.371);
      }
  }
}
.btn {
  &:hover, &:focus {
    box-shadow:  none !important;
  }
}
.btn-link{
  color: #686868 !important;
}
input{
  &:focus{
    box-shadow: none !important;
  }
}
.btn-primary{
  background-color: #6868ad !important;
  border-color: #6868ad !important;
}
$base-color: #6868ad !default;
$primary: #6868ad !default;
$--bs-primary:#6868ad ;
.text-main{
  color: $base-color !important;
}
#root{
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  margin-bottom: 40px;
}
.footer{
  z-index: 2;
  background: #f9fafa !important;
}
.container, .container-fluid {
  padding: 0 30px !important;
}
.footer-corporate{
  font-size: 12px !important;
  color: gray !important;
}
p{
  margin-bottom: .5rem !important;
}
input.invalid,textarea.invalid{
  border-color: #dc3545 !important;
}
input.valid,textarea.valid{
  border-color: #198754 !important;
}