.search-box{
    max-width: 800px;
    &__input-box{
        &__input{

        }
    }
    &__search-button{
        border: 1px solid transparent !important;
        border-radius: 0 .25rem .25rem 0 !important;
    }
    &.on-center{
        position: absolute !important;
        top: 36%;
        left: calc(50% - 400px);
        max-width: 800px;
    }
}
$base-color: #6868ad;
$primary-color: #6868ad;