.header-container
{
    &__logout-box,&__account-box{
        &__logout,&__account{
            svg{
                height: 12px;
            }
            &__text{
                font-size: 12px !important;
                color: gray !important;
            }
        }
    }
    &__logo-box{
        &.center-menu{
            position: absolute;
            left: calc(50% - 100px);
            top: 25%;
        }
    }
}
.header-panel{
    padding: 0 10px !important;
    overflow: hidden;
}
.top-menu{
    background: rgba(104, 104, 173 , 14%);
}