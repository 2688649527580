.login-container{
    max-width: 100% !important;
    background: #efefef !important;
    padding: 0 !important;
    overflow: hidden;
    &__slider-box{
        padding-left: 0 !important;
    }
    &__login-box{
        display: flex;
        align-items: center;
        justify-content: center;
        &__wrapper{
            max-width: 400px;
            &__title{
                font-weight: bold;
                color: #686868;
                margin-bottom: 20px;
            }
            &__form-box{
                &__form{
                    &__mail,&__password,&__name,&__phone{
                        input{
                            border: 1px solid #686868;
                            &:focus{
                                border-color: #6868ad !important;
                            }
                        }
                        
                    }
                    &__password{
                        margin-bottom: 40px;
                    }
                    &__submit-button{
                        background: #6868ad !important;
                        border-color: #6868ad !important;
                        padding:12px !important;
                    }
                    &__forget-button{
                        color: #686868 !important;
                    }
                }
            }
        }
    }
}
.show-password{
    top: 30%;
    right: 20px;
}
#registerTip{
    max-width: 300px;
}
.brand-add-desc{
    font-size: 10px;
    color: #686868;
    margin-top: 10px;
}