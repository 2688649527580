td,th{
    white-space: nowrap;
    width: fit-content;
    min-width: fit-content;
    font-size: 12px;
    cursor: pointer;
    padding: .2rem !important;
    &.product-detail-col{
        white-space: normal !important;
        width: 100% !important;
        min-width: auto !important;
        cursor: unset;
    }
    &.brand-col{
        color: #A1A1A1;
    }
    &.expand-button{
        cursor: pointer;
    }
}
th{
    font-size: 12px !important;
    font-weight: 500 !important;
    padding: .5rem .2rem !important;
    background-color: #e3e3e3 !important;
}
tbody{
    
}
tr{
    &:nth-child(4n-1){
    background-color: #f5f7f8ab;
    ;
    }
    &.expandable{
        transition: all ease 0s;
        height: 0;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: -100px;
        *{
            visibility: hidden;
            opacity: 0;
            overflow: hidden;   
            transition: all ease 0s;
        }

        &.open{
            height: auto;
            visibility: visible;
            opacity: 1;
            position: relative;
            top: 0;
            transition: all ease .3s;
            *{
                visibility: visible;
                opacity: 1;
                overflow: visible;
                transition: all ease .3s;
            }

        }
    }
}
.report-input{
    resize: none;
    height: 200px !important;
}
.report-alert{
    top: 100px;
    right: 50px;
}
.product-list{
    min-height: 300px;
}
.filter-product-brand{
    max-width: 200px;
    min-width: 200px;
    [class^="-ValueContainer"],[class*="-ValueContainer"]{
        max-height: 38px;
        overflow: auto;
    }
}
[class^="-ValueContainer"],[class*="-ValueContainer"]{
    max-height: 58px !important;
    height: 36px;
    overflow: auto !important;
    max-width: 200px;
}
[id^="react-select-"],[id*="react-select-"]{
    [class^="-MenuList"],[class*="-MenuList"]{
        div{
            display: flex !important;
            align-items: center;
            label{
                padding-left: 5px;
                font-size: 12px;
            }
        }
    }
}