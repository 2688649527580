.account-form{
    max-width: 500px;
}
.account-file-upload{
    button {
        min-width: fit-content;
        margin-left: 10px;
    }
}
.account-file-wrapper{
    margin-top: 100px;
}